html {
    font-family: "futura-pt-bold",sans-serif;

    font-size: 16px;

    @include layout( $l-medium ) {
        font-size: 1.2rem;
    }

    @include layout( $l-large ) {
        font-size: 1.4rem;
    }
}

h1, h2, h3, h4 {
    text-transform: uppercase;
    //letter-spacing: -0.1rem;
    font-weight: bold;
}

p {
    font-size: 1rem;
    font-family: "futura-pt", Helvetica, sans-serif;

    a {
        font-weight: bold;
    }
}

.t-heading {
    font-size: 1.5rem;
    line-height: 1em;
    text-transform: uppercase;
    font-family: "futura-pt-bold",sans-serif;

    @include layout($l-medium) {
        font-size: 2.2rem;
        line-height: 0.9em;
    }

    @include layout($l-xlarge) {
        font-size: 2.8rem;
    }
}

.t-quote {
    font-size: 3rem;
    line-height: 0.9em;
    letter-spacing: -0.1rem;
    padding: 0 2rem;
    text-align: center;
    margin-bottom: 0.5em;

    @include layout( $l-medium ) {
        font-size: 4rem;
    }

    @include layout( $l-large ) {
        font-size: 5rem;
    }
}
