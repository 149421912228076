.section {
    min-height: 100vh;
    height: 100%;
    border: 2rem solid $c-red;
    &:not(:first-child) {
       margin-top: -2rem;
    }
}

.section-content {
    @include grid-wrap(1);
    background-color: $c-red;
}

.section-contact {
    position: relative;
    background-color: $c-lightblue;
}

.section-quote, .section-subscribe {
    background-image: url('/built/img/pattern.png');
    background-repeat: repeat;
    background-size: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include layout( $l-xlarge ) {
        background-size: 30%;
    }
}

.section-subscribe {
        background-image: none;
        align-items: start;
    @include layout( $l-small ) {
        background-image: url('/built/img/pattern.png');
        align-items: center;
    }
}

.content_title {
    @include grid-item(1);
    @include layout( $l-large ) {
        @include grid-item(1/2);
        padding-right: 4rem;
    }
}

.content_copy {
    @include grid-item(1);
    margin-top: 3rem;
    @include layout( $l-large ) {
        @include grid-item(1/2);
        margin-top: 4rem;
    }
}

.content-container {
    max-width: 80rem;
    margin: 6rem auto 4rem auto;
    @include layout( $l-medium ) {
        padding: 0 2rem;
    }
}

.picture_bg {
    width: 100%;
    min-height: calc(100vh - 4rem);
    height: 100%;
    background-size: cover;
}

.quote-container {
    max-width: 1070px;
    margin: 0 auto;
}

.quote-img {
    margin: 0 auto;
    max-width: 10rem;
}

.quote-description {
    display: block;
    text-align: center;
    margin-top: 0.4rem;
    color: $c-lightblue;
}

.contact-container {
    padding: 2rem 4rem 2rem 2rem;

    @include layout($l-large) {
        @include grid-item(2/3);
    }

    @include layout($l-xlarge) {
        @include grid-item(1/2);
    }
}

.contact-heading {
    color: $c-purple;
}

.contact-detail {
    color: $c-red;

    & > a > br {
        @include layout($l-small) {
            display: inline;
        }

        @include layout($l-medium) {
            display: none;
        }
    }
}

.contact-logo {
    position: absolute;
    top: 1rem;
    right: 1rem;
    @include layout( $l-medium ) {
        bottom: 3rem;
        top: auto;
        right: 3rem;
    }
}

.contact-theatre {
    position: absolute;
    bottom: 2rem;
    padding-right: 2.5rem;
    & a:hover {
        color: $c-purple;
    }
}

.icon-logo {
    fill: $c-red;
}
