.section-video {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.video-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
    overflow: hidden;
}
.section-video iframe {
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.video-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    max-width: 50%;
    @include layout( $l-medium ) {
        max-width: 20rem;
    }
}

.down-arrow-container  {
    position: fixed;
    left: 50%;
    bottom: 5%;
    transform: translate(-50%,-50%);
}

.down-arrow {
    fill: $c-red;
    animation: bounce 2s;
    animation-iteration-count: 3;
    animation-delay: 1s;
}

.logo {
    position: fixed;
    top: 3rem;
    right: 3rem;

    & > .o-icon {
        fill: $c-lightblue;
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
    40% {
    transform: translateY(-20px);
  }
    60% {
    transform: translateY(-10px);
  }
}
