$l-small: 350px;
$l-medium: 750px;
$l-large: 1200px;
$l-xlarge: 1800px;

$c-purple: #3c3490;
$c-white: #fff;
$c-black: #000;
$c-red: #eb1f48;
$c-lightblue: #92d8ed;

.c-red {
    color: $c-red;
}

.c-purple {
    color: $c-purple;
}

.c-lightblue {
    color: $c-lightblue;
}

.bg-red {
    background-color: $c-red;
}

.bg-purple {
    background-color: $c-purple;
}

.bg-lightblue {
    background-color: $c-lightblue;
}
