.o-icon {
    width: 4em;
    height: 4em;
    fill: currentColor;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 1;

    svg {
        width: 1em;
        height: 1em;
    }
}


