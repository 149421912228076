html {
	font-size: 12px;
	font-family: Helvetica, Arial, sans-serif;
	background-color: $c-red;
	color: #fff;

	@include layout( $l-small ) {
		font-size: 14px;
	}

	@include layout( $l-medium ) {
		font-size: 16px;
	}

}


