.subscribe-container {
    text-align: center;
    background-color: $c-lightblue;
    padding: 2rem;
    @include layout( $l-small ) {
        margin: 0 2rem;
    }
    @include layout( $l-medium ) {
        padding: 3rem 7rem;
    }
}

.subscribe-heading {
    //font-size: 3rem;
    //line-height: 3rem;
    color: $c-red;
}

.subscribe-copy {
    margin: 1rem auto;
    color: $c-purple;
    font-family: "futura-pt-bold",sans-serif;
    text-transform: uppercase;
    max-width: 20rem;
}


input::placeholder {
    color: $c-purple;
    text-transform: uppercase;
    &:focus {
        opacity: 0;
    }
}

input {
    &:focus {
        color: $c-red;
    }
}

.subscribe-form {
    padding: 1rem;
}

.subscribe-input {
    padding: 1rem 0;
    width: 100%;
    color: $c-purple;
    border-bottom: 2px solid $c-purple;
    text-transform: uppercase;
    &:focus {
        border-bottom: 2px dashed $c-red;
    }
}

.btn {
    display: block;
    background: $c-red;
    text-transform: uppercase;
    text-align: center;
    margin: 4rem auto 0 auto;
    padding: 1rem;
    &:hover {
        background: $c-purple;
    }
}
