
@mixin space-horizontally( $space:1rem ) {
	> *:not(:last-child) {
		margin-right: #{$space};
	}
}

@mixin space-vertically( $space:1rem ) {
	> *:not(:last-child) {
		margin-bottom: #{$space};
	}
}


@mixin layout( $size ) {

	@media ( min-width: $size ) {
		@content;
	}

}

@mixin clearfix {
	&::after {
		clear: both;
		content: "";
		display: table;
	}
}

@mixin truncate {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin text-width {
	max-width: 30rem;
	@include layout( $l-medium ) {
		max-width: 20rem;
	}
	@include layout( $l-xlarge ) {
		max-width: 30rem;
	}
}
